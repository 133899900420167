import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const FireSafetyServiceExcellenceSection = () => {
  const experienceTitle = "Training All Components of Fire & Safety Services ";
  const experienceDescription =
    "Our massive experience in the fire and safety training domain and relentless pursuit towards quality-driven services have taken us into every nook and corner of India and there is no looking back!";
  const locationsLabel = "Training Effectiveness";
  const employeesLabel = "Clients";
  const clientsLabel = "Taskforce";
  const locationsCount = "100%";
  const employeesCount = "100 +";
  const clientsCount = "16K +";

  return (
    <>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </>
  );
};

export default FireSafetyServiceExcellenceSection;
